import React from 'react';
import 'firebase/auth';
import 'firebase/firestore';

import { AuthProvider } from './src/app';
import { Layout } from './src/components/Layout';

import './src/css/styles.css';

export const wrapRootElement = ({ element }) => <AuthProvider>{element}</AuthProvider>;

export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;
