import React, { forwardRef } from 'react';
import { Link } from 'gatsby';
import { MdLibraryAdd } from 'react-icons/md';

import { useAuth } from '../app';
import { Avatar } from '../components/Avatar';
import { Button } from '../components/Button';
import { routes } from '../utils/constants';

import css from './Navigation.module.css';

const NavItem = ({ children, to, className }) => (
  <li>
    <Link to={to} className={className ? className : css.item} activeClassName="bar">
      {children}
    </Link>
  </li>
);

export const Navigation = forwardRef(({ expanded }, ref) => {
  const { isLoggedIn, user } = useAuth();

  return (
    <nav role="navigation" className={css.nav}>
      <ul ref={ref} className={expanded ? css.list : css.listHidden} id="nav">
        <NavItem to={routes.index}>Zoznam ponúk</NavItem>
        <NavItem to={routes.project}>O projekte</NavItem>
        {isLoggedIn && (
          <NavItem to={routes.profile}>
            <Avatar src={user.photoURL} /> Profil
          </NavItem>
        )}
        <li>
          <Button to={`${routes.item}${routes.add}`} type="main" className={css.add}>
            <MdLibraryAdd /> Pridať inzerát
          </Button>
        </li>
      </ul>
    </nav>
  );
});
