import React from 'react';
import css from './TopHeader.module.css';
import { FaFacebookSquare, FaTwitter } from 'react-icons/fa';
import { Email } from './Email';

export const TopHeader = () => (
  <div className={css.topHeader}>
    <ul className={css.list}>
      <li className={css.share}>zdieľajte nás na</li>
      <li>
        <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.dorucim.online" target="_new">
          <FaFacebookSquare size="1.5rem" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/intent/tweet?status=Pomocná ruka v čase pandémie+https://www.dorucim.online"
          target="_new"
        >
          <FaTwitter size="1.5rem" />
        </a>
      </li>
      <li className={css.email}>
        <Email />
      </li>
    </ul>
  </div>
);
