import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import { ToastContainer } from 'react-toastify';
import { IoMdMenu, IoMdClose } from 'react-icons/io';

import { TopHeader } from './TopHeader';
import { Navigation } from './Navigation';
import { ScrollToTop } from './ScrollToTop';
import { routes } from '../utils/constants';
import { useOnClickOutside } from '../utils/useOnClickOutside';
import { useSiteMetadata } from '../utils/useSiteMetadata';

import 'react-toastify/dist/ReactToastify.css';
import css from './Layout.module.css';
import logo from '../images/logo.png';

export const Layout = ({ children }) => {
  const toggelRef = useRef();
  const navRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const { title } = useSiteMetadata();

  useOnClickOutside(navRef, (e) => {
    if (!toggelRef.current.contains(e.target)) {
      setExpanded(false);
    }
  });

  return (
    <>
      <a href="#main" className="sr-only">
        Prejsť na obsah
      </a>

      <TopHeader />

      <header role="banner" className={css.header}>
        <div className={css.logo}>
          <Link to="/">
            <img src={logo} alt={title} />
          </Link>
        </div>
        <button
          ref={toggelRef}
          className={css.toggler}
          aria-controls="nav"
          aria-expanded={expanded}
          aria-label="Zobraziť navigáciu"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <IoMdClose /> : <IoMdMenu />}
        </button>
        <Navigation ref={navRef} expanded={expanded} />
      </header>

      {children}

      <footer role="contentinfo" className={css.footer}>
        <ul className={css.footerLinks}>
          <li>
            <Link to={routes.terms}>Podmienky používania stránky</Link>
          </li>
          <li>
            <Link to={routes.gdpr}>Zásady ochrany osobných údajov</Link>
          </li>
        </ul>

        <div>© {new Date().getFullYear()}</div>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </footer>

      <ScrollToTop />
    </>
  );
};
