import React from 'react';
import { IconContext } from 'react-icons';
import { Link } from 'gatsby';

import css from './Button.module.css';

export const Button = ({ children, to, onClick, className, type = 'default' }) => {
  const cssStyles = `${css[type]} ${className}`;

  return (
    <IconContext.Provider value={{ size: '1.25rem' }}>
      {to ? (
        <Link to={to} className={cssStyles}>
          {children}
        </Link>
      ) : (
        <button onClick={onClick} className={cssStyles}>
          {children}
        </button>
      )}
    </IconContext.Provider>
  );
};
