export const routes = {
  index: '/',
  project: '/projekt',
  gdpr: '/gdpr',
  terms: '/podmienky-pouzivania',
  login: '/login',
  profile: '/profil',
  item: '/polozka',
  add: '/pridat',
};

export const sectors = [
  'Automobily',
  'Bývanie',
  'Drogéria',
  'Elektrotechnika',
  'Hobby',
  'Kadernícke potreby',
  'Kvetinárstvo',
  'Textil a obuv',
  'Požičovne',
  'Papiernictvo',
  'Potraviny',
  'Preprava tovaru',
  'Stavebný materiál',
  'Športové potreby',
  'Záhrada',
  'Zdravotnícke potreby',
  'Zvieratá',
  'Iné',
];

export const okresy = [
  'Bratislava I',
  'Bratislava II',
  'Bratislava III',
  'Bratislava IV',
  'Bratislava V',
  'Malacky',
  'Pezinok',
  'Senec',
  'Dunajská Streda',
  'Galanta',
  'Hlohovec',
  'Piešťany',
  'Senica',
  'Skalica',
  'Trnava',
  'Bánovce nad Bebravou',
  'Ilava',
  'Myjava',
  'Nové Mesto nad Váhom',
  'Partizánske',
  'Považská Bystrica',
  'Prievidza',
  'Púchov',
  'Trenčín',
  'Komárno',
  'Levice',
  'Nitra',
  'Nové Zámky',
  'Šaľa',
  'Topoľčany',
  'Zlaté Moravce',
  'Bytča',
  'Čadca',
  'Dolný Kubín',
  'Kysucké Nové Mesto',
  'Liptovský Mikuláš',
  'Martin',
  'Námestovo',
  'Ružomberok',
  'Turčianske Teplice',
  'Tvrdošín',
  'Žilina',
  'Banská Bystrica',
  'Banská Štiavnica',
  'Brezno',
  'Detva',
  'Krupina',
  'Lučenec',
  'Poltár',
  'Revúca',
  'Rimavská Sobota',
  'Veľký Krtíš',
  'Zvolen',
  'Žarnovica',
  'Žiar nad Hronom',
  'Bardejov',
  'Humenné',
  'Kežmarok',
  'Levoča',
  'Medzilaborce',
  'Poprad',
  'Prešov',
  'Sabinov',
  'Snina',
  'Stará Ľubovňa',
  'Stropkov',
  'Svidník',
  'Vranov nad Topľou',
  'Gelnica',
  'Košice I',
  'Košice II',
  'Košice III',
  'Košice IV',
  'Košice - okolie',
  'Michalovce',
  'Rožňava',
  'Sobrance',
  'Spišská Nová Ves',
  'Trebišov',
];
