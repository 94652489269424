import React, { useEffect, useState } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import throttle from 'lodash.throttle';

import { isBrowser } from '../utils';

import css from './ScrollToTop.module.css';

export const ScrollToTop = () => {
  const [className, setClassName] = useState(css.top);

  useEffect(() => {
    let top = 0;

    const toggle = throttle(() => {
      const y = window.scrollY;
      setClassName(y > 500 && y > top ? css.visible : css.top);
      top = y;
    }, 250);

    if (isBrowser()) {
      window.addEventListener('scroll', toggle);

      return () => window.removeEventListener('scroll', toggle);
    }
  }, []);

  const handleOnClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  return (
    <a href="#top" onClick={handleOnClick} className={className}>
      <IoIosArrowUp size="1.5rem" />
    </a>
  );
};
