import React, { useEffect, useContext, useReducer } from 'react';
import { navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';

import { printError } from '../utils';
import { routes } from '../utils/constants';

export const providerGoogle = () => new firebase.auth.GoogleAuthProvider();

const defaultState = {
  state: 'idle', // idle | pending | non-authenticated | authenticated | verified | error
  user: null,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  sendEmailVerification: () => {},
  sendPasswordResetEmail: () => {},
};

const authReducer = (state, action) => {
  if (process.env.GATSBY_CONTEXT !== 'production') {
    console.log(`dispatched ${action.type} action`);
  }

  switch (action.type) {
    case 'sign-in':
      return {
        ...state,
        state: action.user.emailVerified ? 'verified' : 'authenticated',
        isLoggedIn: true,
        user: action.user,
      };

    case 'sign-out':
      return {
        ...state,
        state: 'non-authenticated',
        isLoggedIn: false,
        user: null,
      };

    case 'pending':
      return {
        ...state,
        state: 'pending',
      };

    case 'error':
      return {
        ...state,
        state: 'error',
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const AuthContext = React.createContext(defaultState);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, defaultState);

  useEffect(() => {
    dispatch({ type: 'pending' });

    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch({
          type: 'sign-in',
          user,
        });
      } else {
        dispatch({
          type: 'sign-out',
          user,
        });
      }
    });

    return unsubscribe;
  }, []);

  const login = async (provider, redirect = undefined) => {
    dispatch({ type: 'pending' });

    try {
      firebase.auth().useDeviceLanguage();
      await firebase.auth().signInWithPopup(provider);

      if (redirect) {
        navigate(redirect);
      }
    } catch (e) {
      printError(e);
      dispatch({ type: 'error' });
    }
  };

  const logout = async () => await firebase.auth().signOut();

  const sendEmailVerification = async (user) => {
    const actionCodeSettings = {
      url: `${process.env.GATSBY_DOMAIN}${routes.profile}?v=${user.email}`,
    };

    firebase.auth().languageCode = 'sk';
    return user.sendEmailVerification(actionCodeSettings);
  };

  const sendPasswordResetEmail = async (email) => {
    firebase.auth().languageCode = 'sk';
    return firebase.auth().sendPasswordResetEmail(email);
  };

  return (
    <AuthContext.Provider value={{ ...state, login, logout, sendEmailVerification, sendPasswordResetEmail }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
